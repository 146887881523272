export default {
  head: {
    title: "דוא\"ל",
    meta: [
      { name: "תיאור", content: "דוא\"ל" }
    ]
  },
  settings: {
    head: "הגדרות דוא\"ל",
    userName: "שם משתמש",
    password: "סיסמה",
    server: "שרת",
    port: "פורט",
    useSSL: "להשתמש ב SSL",
    connectAndSave: "לשמור",
    checkSmtp: "בדיקת SMTP",
    checkImap: "בדיקת IMAP",
    gmail: "Gmail",
    outlook: "Outlook",
    custom: "אחר",
    imap: "IMAP (נכנס)",
    smtp: "SMTP (יוצא)",
    connect: "חיבור חשבון",
    submitedConnection: "חשבון {0} חובר בהצלחה.",
    disconnect: "ניתוק חשבון",
    imapSuccess: "IMAP חובר בהצלחה",
    imapError: "חיבור IMAP לא נצליח",
    smptpError: "חיבור SMTP לא נצליח",
    smtpSuccess: "SMTP חובר בהצלחה",
    smtpTestSubject: "בדיקת SMTP"
  },
  send: {
    head: 'דוא"ל',
    send: "שליחה",
    filesHelpText: "ניתן לגרור קבצים לכאן"
  },
  list: {
    empty: "אין מיילים להצגה",
    settingTooltip: "הגדרות",
    markAsRead: "סמן כנקרא",
    markAsUnread: "סמן כלא נקרא",
    showing: "מציג",
    of: "מתוך"
  },
  patientsFilesPopup: {
    title: "אנא בחרו מטופל ותיקייה לשמירה",
    emailMessagePatients: "מטופלים",
    patient: "מטופל",
    folder: "תיקייה",
    validation: {
      patient: "יש לבחור מטופל"
    }
  },
  compose: 'מייל חדש',
  inbox: 'דואר נכנס',
  starred: 'מסומנים בכוכבית',
  draft: 'טיוטה',
  sentMail: 'נשלח',
  trash: 'סל מחזור',
  labels: 'תגיות',
  download: 'הורדה',
  reply: 'השב',
  replyAll: 'השב לכולם',
  forward: 'העבר',
  emailSent: "האימייל נשלח בהצלחה",
  saveInPatientFolder: "שמור בתיקיית מטופל"
}
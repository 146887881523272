export default {
  head: {
    title: "הודעות",
    meta: [
      { name: "description", content: "הודעות" }
    ]
  },
  chatSettings: {
    connect: "חיבור",
    whatsapp: "WhatsApp",
    title: "הגדרות צ'אט",
    tooltip: "הגדרות WhatsApp וסמס",
    options: {
      whatsapp: "הגדרות WhatsApp",
      sms: "הגדרות SMS"
    }
  },
  addNewPatient: "יצירת מטופל חדש",
  clearChat: "ניקוי הצ'אט",
  clearThisChat: "האם לנקות את הצ'אט?",
  clientNotRegistered: "המטופל {0} אינו רשום במערכת",
  clientSearch: "חיפוש מטופלים לפני שם או מספר טלפון",
  copy: "העתק",
  enterMessage: "הקלדת הודעה",
  fileSavedInPatientFolder: "הקובץ נשמר בתיקיית מטופל",
  fileWasNotSavedInPatientFolder: "הקובץ לא נשמר",
  messageSent: "ההודעה נשלחה",
  muteNotifications: "כבה התראות עבור הצ'אט הזה",
  unmuteNotifications: "הפעל התראות עבור הצ'אט הזה",
  noSearchResultsFound: "אין תוצאות",
  saveInPatientFolder: "שמור בתיקיית מטופל",
  search: "חיפוש בשיחה",
  sendingSms: "(שליחת sms)",
  sendingWhatsapp: "(שליחת whatsapp)",
  smsMessage: "הודעת SMS",
  whatsappMessage: "הודעת WhatsApp",
  noActiveWhatsapp: "לא מחובר חשבון WhatsApp",
  noActiveSms: "לא מחובר חשבון Sms"
}

import GraphqlService, { type Order } from "../GraphqlService";
import {
  type TreatmentStatusSchema, treatmentStatusSchemaAllFields,
  type TreatmentSchema, treatmentSchemaAllFields,
  type TreatmentPlanSchema, treatmentPlanSchemaAllFields,
} from "@/models/schemas";
import type { SaveTreatmentPlanInput, SaveTreatmentInput } from "@/models/api/mutations/PatientModels";
import type { FilterTreatmentInput } from "@/models/api/queries/PatientModels";
import type { TreatmentPlanProfitModel } from "@/models/api/queries/TreatmentModels";
import type { TabulatorParams, Filter } from "@/models/interfaces";
import type { DiscountType, TreatmentStageStatus, TreatmentDenture } from "@/models/enums";
import { TreatmentPaidStatus } from "@/models/enums";

export default class PatientTreatmentService {
  //#region Treatments
  async getTreatmentStatusesAll(order?: Order[]) {
    const { data } = await GraphqlService.queryGql<TreatmentStatusSchema[]>({
      method: "treatmentStatusesAll",
      fields: treatmentStatusSchemaAllFields,
      order: order ?? [{ field: "order", value: "ASC" }]
    });
    return data ?? [];
  }

  async getPatientTreatmentsAll(patientId: number, input?: FilterTreatmentInput, fields?: string[], order?: Order[]) {
    const { data } = await GraphqlService.queryGql<TreatmentSchema[]>({
      method: "patientTreatmentsAll",
      fields: fields ?? treatmentSchemaAllFields,
      variables: [{ field: "id", value: patientId, valueType: "ID!" }, { field: "input", value: input, valueType: "FilterTreatmentInput" }],
      order: order ?? [{ field: "startTime", value: "DESC" }]
    });
    return data ?? [];
  }

  async getPatientActiveTreatmentsAll(patientId: number, fields?: string[], order?: Order[]) {
    const { data } = await GraphqlService.queryGql<TreatmentSchema[]>({
      method: "patientActiveTreatmentsAll",
      fields: fields ?? treatmentSchemaAllFields,
      variables: [{ field: "id", value: patientId, valueType: "ID!" }],
      order: order ?? [{ field: "startTime", value: "DESC" }]
    });
    return data ?? [];
  }

  async getPatientTreatments(patientId: number, params: TabulatorParams, input?: FilterTreatmentInput, fields?: string[]) {
    return await GraphqlService.getItems<TreatmentSchema>("patientTreatments", fields ?? treatmentSchemaAllFields, params, {
      variables: [{ field: "id", value: patientId, valueType: "ID!" }, { field: "input", value: input, valueType: "FilterTreatmentInput" }]
    });
  }

  async getPatientTreatment(treatmentId: number, patientId: number) {
    return await GraphqlService.getItem<TreatmentSchema>("patientTreatment", treatmentSchemaAllFields, treatmentId, {
      variables: [{ field: "patientId", value: patientId, valueType: "ID!" }]
    });
  }

  async getPatientTreatmentByAppointment(appointmentId: number, patientId: number, fields?: string[]) {
    const { data } = await GraphqlService.queryGql<TreatmentSchema[]>({
      method: "patientTreatmentsAll",
      fields: fields ?? treatmentSchemaAllFields,
      variables: [{ field: "id", value: patientId, valueType: "ID!" },],
      filter: [{ field: "appointmentId", type: "=", value: appointmentId }]
    });
    return data?.length ? data[0] : null;
  }

  async getPopupPatientTreatments(patientId: number, params: TabulatorParams) {
    return await GraphqlService.getItems<TreatmentSchema>("popupPatientTreatments", treatmentSchemaAllFields, params, {
      variables: [{ field: "id", value: patientId, valueType: "ID!" }]
    });
  }

  async getPopupPatientTreatmentsAll(patientId: number) {
    const { data } = await GraphqlService.queryGql<TreatmentSchema[]>({
      method: "popupPatientTreatmentsAll",
      fields: treatmentSchemaAllFields,
      variables: [{ field: "id", value: patientId, valueType: "ID!" }],
    });
    return data ?? [];
  }

  async savePatientTreatment(input: SaveTreatmentInput, fields?: string[]) {
    const { data } = await GraphqlService.mutateGql<TreatmentSchema>({
      method: "savePatientTreatment",
      item: "treatment",
      fields: fields ?? treatmentSchemaAllFields,
      variables: [{ field: "input", value: input, valueType: "SaveTreatmentInput" }]
    })
    return data;
  }

  async changePatientTreatmentStatus(treatmentId: number, patientId: number, treatmentStatusId: number) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "changePatientTreatmentStatus",
      variables: [
        { field: "id", value: treatmentId, valueType: "ID!" },
        { field: "patientId", value: patientId, valueType: "ID!" },
        { field: "statusId", value: treatmentStatusId, valueType: "ID!" },
      ]
    });
    return data;
  }

  async markPatientTreatmentAsPaid(treatmentId: number, patientId: number, cancel?: boolean) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "markPatientTreatmentAsPaid",
      variables: [
        { field: "id", value: treatmentId, valueType: "ID!" },
        { field: "patientId", value: patientId, valueType: "ID!" },
        { field: "cancel", value: cancel ?? false, valueType: "Boolean!" },
      ]
    });
    return data;
  }

  async deletePatientTreatment(treatmentId: number, patientId: number) {
    return await GraphqlService.deleteItem("deletePatientTreatment", treatmentId, {
      variables: [{ field: "patientId", value: patientId, valueType: "ID!" }],
    });
  }
  //#endregion

  //#region Treatment plans
  async getPatientTreatmentPlans(patientId: number, params: TabulatorParams, fields?: string[]) {
    return await GraphqlService.getItems<TreatmentPlanSchema>("patientTreatmentPlans", fields ?? treatmentPlanSchemaAllFields, params, {
      variables: [{ field: "id", value: patientId, valueType: "ID!" }]
    });
  }

  async getPatientTreatmentPlansAll(patientId: number, fields?: string[] | null, filter?: Filter[], order?: Order[]) {
    const { data } = await GraphqlService.queryGql<TreatmentPlanSchema[]>({
      method: "patientTreatmentPlansAll",
      fields: fields ?? treatmentPlanSchemaAllFields,
      variables: [{ field: "id", value: patientId, valueType: "ID!" }],
      filter: filter,
      order: order ?? [{ field: "createdAt", value: "DESC" }]
    });
    return data ?? [];
  }

  async getPatientTreatmentPlan(treatmentPlanId: number, patientId: number, fields?: string[]) {
    return await GraphqlService.getItem<TreatmentPlanSchema>("patientTreatmentPlan", fields ?? treatmentPlanSchemaAllFields, treatmentPlanId, {
      variables: [{ field: "patientId", value: patientId, valueType: "ID!" }]
    });
  }

  async getPatientTreatmentPlanProfit(patientId: number, treatmentPlanId: number) {
    const { data } = await GraphqlService.queryGql<TreatmentPlanProfitModel>({
      method: "patientTreatmentPlanProfit",
      fields: ["id", "expensesHours", "expensesMaterial", "total", "profit"],
      variables: [
        { field: "id", value: patientId, valueType: "ID!" },
        { field: "treatmentPlanId", value: treatmentPlanId, valueType: "ID!" }
      ]
    });
    return data;
  }

  async canFinishPatientTreatmentPlan(patientId: number, treatmentPlanId: number) {
    const { data } = await GraphqlService.queryGql<boolean>({
      method: "canFinishPatientTreatmentPlan",
      variables: [
        { field: "id", value: patientId, valueType: "ID!" },
        { field: "treatmentPlanId", value: treatmentPlanId, valueType: "ID!" }
      ]
    });
    return data!;
  }

  async getPopupPatientTreatmentPlans(patientId: number, params: TabulatorParams) {
    return await GraphqlService.getItems<TreatmentPlanSchema>("popupPatientTreatmentPlans", treatmentPlanSchemaAllFields, params, {
      variables: [{ field: "id", value: patientId, valueType: "ID!" }]
    });
  }

  async getPopupPatientTreatmentPlansAll(patientId: number) {
    const { data } = await GraphqlService.queryGql<TreatmentPlanSchema[]>({
      method: "popupPatientTreatmentPlansAll",
      fields: treatmentPlanSchemaAllFields,
      variables: [{ field: "id", value: patientId, valueType: "ID!" }],
    });
    return data ?? [];
  }

  async updatePatientTreatmentPlan(treatmentPlanId: number, patientId: number, fields: {
    doctorId: number,
    status: TreatmentStageStatus,
    denture: TreatmentDenture,
  }) {
    return await GraphqlService.deleteItem("updatePatientTreatmentPlan", treatmentPlanId, {
      variables: [
        { field: "patientId", value: patientId, valueType: "ID!" },
        { field: "doctorId", value: fields.doctorId, valueType: "ID!" },
        { field: "status", value: fields.status, valueType: "TreatmentStageStatus!" },
        { field: "denture", value: fields.denture, valueType: "TreatmentDenture!" },
      ],
    });
  }

  async updatePatientTreatmentPlanRemarks(treatmentPlanId: number, patientId: number, fields: {
    title: string,
    description: string | null,
    descriptionPrint: boolean,
  }) {
    return await GraphqlService.deleteItem("updatePatientTreatmentPlanRemarks", treatmentPlanId, {
      variables: [
        { field: "patientId", value: patientId, valueType: "ID!" },
        { field: "title", value: fields.title, valueType: "String!" },
        { field: "description", value: fields.description, valueType: "String" },
        { field: "descriptionPrint", value: fields.descriptionPrint, valueType: "Boolean!" },
      ],
    });
  }

  async updatePatientTreatmentPlanDiscount(treatmentPlanId: number, patientId: number, discountType: DiscountType, discountValue: number) {
    return await GraphqlService.deleteItem("updatePatientTreatmentPlanDiscount", treatmentPlanId, {
      variables: [
        { field: "patientId", value: patientId, valueType: "ID!" },
        { field: "discountType", value: discountType, valueType: "DiscountType!" },
        { field: "discountValue", value: discountValue, valueType: "Decimal!" },
      ],
    });
  }

  async savePatientTreatmentPlan(input: SaveTreatmentPlanInput, fields?: string[]) {
    const { data } = await GraphqlService.mutateGql<TreatmentPlanSchema>({
      method: "savePatientTreatmentPlan",
      item: "treatmentPlan",
      fields: fields ?? treatmentPlanSchemaAllFields,
      variables: [{ field: "input", value: input, valueType: "SaveTreatmentPlanInput" }],
    })
    return data;
  }

  async duplicatePatientTreatmentPlan(treatmentPlanId: number, patientId: number) {
    return await GraphqlService.deleteItem("duplicatePatientTreatmentPlan", treatmentPlanId, {
      variables: [{ field: "patientId", value: patientId, valueType: "ID!" }],
    });
  }

  async deletePatientTreatmentPlan(treatmentPlanId: number, patientId: number) {
    return await GraphqlService.deleteItem("deletePatientTreatmentPlan", treatmentPlanId, {
      variables: [{ field: "patientId", value: patientId, valueType: "ID!" }],
    });
  }
  //#endregion
}

export default {
  head: {
    title: "טיפול",
    meta: [
      { name: "description", content: "תיאור דף הטיפול" }
    ]
  },
  general: {},
  treatmentPlan: {
    label: "תוכנית טיפול",
    options: {
      allTreatments: "כל הטיפולים",
      estimate: "הצעת מחיר",
      plan: "תוכנית טיפול",
    }
  },
  patientNumber: "מספר מטופל",
  doctor: "רופא",
  doctorPlaceholder: "לבחור רופא",
  status: {
    label: "סטטוס",
    options: {
      estimate: "הצעת מחיר",
      active: "פעיל",
      finished: "גמור",
      canceled: "מבוטל",
    }
  },
  alert: {
    finishedPlan: {
      title: "השלמת תוכנית טיפול",
      text: "שמנו לב שיש עוד טיפולים בתוכנית הטיפול שלא הסתיימו. לכן לא ניתן לשנות את סטטוס התכנית",
    },
    finishedTreatment: {
      title: "הוספת הטיפול לרשימת הטיפולים שהסתיימו",
      text: "לתשומת ליבך: הטיפול הנבחר יעבור לרשימת הטיפולים שהסתיימו. האם הטיפול הסתיים?",
      yes: "כן אפשר לצרף",
      no: "עדיין לא",
    }
  },
  tip: {
    clientCard: "כרטיס לקוח",
    conclusion: "ממצאים",
    xRay: "רנטגן",
    photos: "צילומים",
    queues: "תורים",
    invoice: "חשבון",
    financialDetails: "פרטים פיננסיים",
    remarks: "הערות",
    history: "היסטוריה",
    addPayment: "תשלום",
    addTreatmentPlan: "הוספת תכנית טיפול",
    duplicateTreatmentPlan: "שכפל תכנית טיפול",
    print: "הדפסה",
    send: "שליחה למטופל",
    deleteTreatmentPlan: "מחק תכנית טיפול"
  },
  filterByStatus: {
    label: "סינון לפי סטטוס",
    options: {
      showAll: "הצג הכל",
      estimate: "הצעת מחיר",
      inPlanning: "בתכנון",
      finished: "הסתיים",
      canceled: "מבוטל",
    }
  },
  denture: {
    label: "משנן",
    options: {
      fixed: "קבוע",
      removable: "נשיר",
      mixed: "מעורב",
    }
  },
  view: {
    label: "תצוגה",
    options: {
      crossTable: "צלב וטבלה",
      graphView: "תצוגת גרף",
      tableView: "תצוגת טבלה",
    }
  },
  treatmentStatus: {
    options: {
      notYetDone: "טרם בוצע",
      inProgress: "בתהליך",
      canceled: "מבוטל",
      approved: "מאושר",
      waiting: "ממתין",
      finished: "הסתיים",
      transferred: "הועבר",
      rejectedByInsuranceCompany: "נדחה ע״י חברת ביטוח"
    }
  },
  removeFilter: "הסר פילטר",
  newTreatment: "טיפול חדש",
  box: {
    code: {
      sec: "סק",
    },
    contextMenu: {
      makeAppointment: "קביעת פגישה",
      viewAppointment: "צפיה בתור",
      changeStatus: "שינוי סטטוס"
    }
  },
  listOfTreatments: "רשימת טיפולים",
  selectColumns: "בחירת עמודות",
  columns: {
    id: "#",
    createdAt: "נוצרה ב",
    followUp: "תאריך מעקב",
    title: "כותרת",
    patientName: "מטופל",
    supplierName: "ספק",
    status: "סטטוס",
  },
  updateTreatment: "עדכון טיפול",
  form: {
    date: "תאריך",
    startTime: "שעת התחלה",
    treatmentDuration: "משך טיפול",
    min: "דק",
    treatingDoctor: "רופא מטפל",
    status: "סטטוס",
    priceList: "מחירון",
    choose: "בחר",
    treatmentCode: "קוד טיפול",
    sec: "סק",
    treatmentName: "שם טיפול",
    frontalOcclusion: "סתימה קדמית",
    teeth: "שיניים",
    surface: "משטח",
    amount: "כמות",
    unitPrice: "מחיר יחידה",
    addTreatment: "הוספת טיפול",
    discount: "הנחה",
    totalAmount: "סה״כ סכום",
    description: "תיאור",
    chooseFixedSentence: "בחירת משפט קבוע",
    add: "הוספה",
    save: "שמור",
    makeAppointment: "קביעת פגישה",
    viewAppointment: "צפיה בתור",
    reallyRemove: "האם ברצונך למחוק טיפול זה?",
    serviceValidation: "נא להוסיף שם טיפול",
    defaultServiceName: "כללי"
  },
  modal: {
    findings: {
      title: "ממצאים",
      addNew: "הוספת ממצא חדש",
      table: {
        columns: {
          createdAt: "תאריך",
          description: "ממצא"
        }
      }
    },
    photos: {
      title: "צילומים",
      totalPhotos: "סה״כ {0} תמונות",
      openFiles: "פתיחת קבצים",
    },
    queues: {
      title: "תורים",
      newQueue: "תור חדש",
      table: {
        columns: {
          startTime: "תאריך",
          doctorName: "רופא מטפל",
          serviceName: "שירות",
          note: "הערות",
        }
      },
    },
    financialDetails: {
      title: "סיכום טיפול",
      totalPlan: "סה״כ תוכנית",
      materialsCost: "עלות חומרים",
      hourlyCost: "עלות שעות",
      discount: "הנחה",
      profit: "רווח",
      ended: "הסתיים",
      paid: "שולם",
      unpaid: "יתרה",
      programDiscount: {
        title: "הנחה לתוכנית",
        desc: "נא לבחור כיצד להגדיר את ההנחה",
        percentage: "אחוזים",
        amount: "סכום",
        askingPrice: "מחיר מבוקש",
      },
      cancel: "ביטול",
      save: "שמירה",
    },
    notes: {
      title: "הערות",
    },
    history: {
      title: "היסטוריה",
      table: {
        columns: {
          createdAt: "תאריך",
          userName: "רופא",
          action: "פעולה",
          entity: "תיאור"
        }
      },
      action: {
        CREATE: "{entityName} #{entityId}",
        UPDATE: "{entityName} #{entityId} {field}: {valueOld} 🡆 {valueNew}", // 🡄
        DELETE: "{entityName} #{entityId}",
      }
    },
    send: {
      notification: "תוכנית הטיפול נשלחה בהצלחה",
      pdfFileMessage: "קובץ PDF של תכנית הטיפול ייווצר באופן אוטומטי ויצורף להודעה",
      uploadFiles: "העלאת קבצים",
      title: "שליחת תוכנית טיפול",
      subject: "כותרת",
      message: "הודעה",
      validation: {
        subject: "כותרת הינה חובה.",
        message: "הודעה הינה חובה.",
        duplicateFile: "הקובץ כבר הועלה"
      }
    }
  }
}

import type { DateTimeString } from "@/models/interfaces";
import type { DefaultOpeningScreen } from "@/models/enums";

export interface DoctorSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt?: DateTimeString;

  haveCalendar: boolean;

  displayName: string;
  licenseNumber: string;
  jobDescription: string;
  profession: string;
  defaultOpeningScreen: DefaultOpeningScreen;
  doctorImage: string;
  signatureJson: string | null;

  percentFromTreatments: boolean;
  percentFromTreatmentsValue: number;
  maxDaysToSetupTheEvent: number;
  minHoursToSetupEvent: number;

  appointmentItemColor: string;
  appointmentItemFontColor: string;
  inactiveWorkHoursColor: string;
  inactiveWorkHoursFontColor: string;
  tmpCustomerAppointmentColor: string;
  tmpCustomerAppointmentFontColor: string;

  calendarHaveColors: boolean;
  calendarGridMinutes: number;
  calendarDayStart: number;
  calendarDayEnd: number;
  calendarAddAppointmentMinutes: number[];
}

export const doctorFields = `
  id
  createdAt
  updatedAt
  deletedAt

  haveCalendar

  displayName
  licenseNumber
  jobDescription
  profession
  defaultOpeningScreen
  doctorImage
  signatureJson

  percentFromTreatments
  percentFromTreatmentsValue
  maxDaysToSetupTheEvent
  minHoursToSetupEvent

  appointmentItemColor
  appointmentItemFontColor
  inactiveWorkHoursColor
  inactiveWorkHoursFontColor
  tmpCustomerAppointmentColor
  tmpCustomerAppointmentFontColor

  calendarHaveColors
  calendarGridMinutes
  calendarDayStart
  calendarDayEnd
  calendarAddAppointmentMinutes
`;

export default {
  head: {
    title: "שירותים",
    meta: [
      { name: "description", content: "שירותים" }
    ]
  },
  createService: "הוספת תבנית טיפול",
  updateService: "עדכון שירות מס' {0}",
  createItem: "הוספת פריט",
  updateItem: "עדכון פריט {0}",
  serviceCategory: "קטגוריית תבנית טיפול",
  itemCategory: "קטגוריית פריט",
  serviceName: "שם תבנית טיפול",
  itemName: "שם פריט",
  serviceCode: "קוד טיפול",
  itemCode: "קוד פריט",
  serviceActive: "השירות פעיל",
  itemActive: "הפריט פעיל",
  emailSubject: 'כותרת (דוא"ל בלבד)',
  description: "תיאור",
  appointmentMinutes: "משך תור בדקות",
  materialPrice: "מחיר החומר בשקלים",
  recallMessageAutomatically: "שליחת הודעת ריקול אוטומטית",
  recallDays: "ימי ריקול",
  message: "הודעה",
  allowWebpageBooking: "ניתן להזמין באינטרנט",
  selectOption: "יש לבחור באפשרות הרצויה",
  sendWithWhatsapp: "לשלוח דרך וואטסאפ",
  sendWithSms: "לשלוח דרך סמס",
  sendWithEmail: 'לשלוח דרך דוא"ל',
  notesInReminder: "הוראות לפני טיפול",
  notesInFollowUp: "הודעת מעקב רפואי (72 שעות)",
  sendFollowUpMessage: "לשלוח הודעת מעקב רפואי לאחר 72 שעות",
  validation: {
    exists: "טיפול קיים בבסיס הנתונים.",
  }
}

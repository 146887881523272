export default {
  head: {
    title: "MedForm - Change Password",
    meta: [
      { name: "description", content: "MedForm change password" }
    ]
  },
  title: "Change Password",
  desc: "Enter new password",
  save: "Save",
  success: "Password successfully changed!",
  input: {
    passwordNew: "New Password",
    passwordRepeat: "Repeat password"
  },
  errors: {
    default: "An error occurred, please try again.",
    invalidToken: "The password reset link has expired or has already been used. Please request a new password reset link."
  }
}

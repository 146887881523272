export default {
  head: {
    title: "לידים",
    meta: [{ name: "description", content: "רשימת פניות" }]
  },
  actions: {
    edit: "עריכה",
    send: "לשלוח",
    sendEmail: 'שליחת פנייה בדוא"ל',
    sendMessage: "שליחת פנייה ב-SMS",
    show: "הצגת פרטי לקוח",
    addPatient: "הוספה לרשימת המטופלים",
    markValid: "סמנו כתקין",
    markInvalid: "סימון רשומה כלא תקינה",
    archive: "העברה לארכיון",
    unarchive: "העבירו לפעילים"
  },
  table: {
    columns: {
      id: "#",
      archived: "פעיל",
      notValid: "תקין",
      followUp: "תאריך מעקב",
      fullName: "שם מלא",
      phone: "טלפון",
      email: "דוא\"ל",
      statusName: "סטטוס",
      serviceName: "שירות",
      channelName: "ערוץ"
    }
  },
  filter: {
    status: "סטטוס",
    all: "הכל",
    new: "חדשים",
    closed: "סגורים",
    created: "נוצר ב",
    followUp: "תאריך מעקב",
    channel: "ערוץ",
    archived: "ארכיון",
    valid: "תקין"
  },
  filterArchived: {
    all: "הכל",
    archive: "ארכיון",
    active: "פעילים",
    // notArchived: "בארכיון",
    // archived: "פעילים"
  },
  filterValid: {
    all: "הכל",
    notValid: "לא תקינים",
    valid: "תקינים"
  },
  status: {
    NEW: "חדש",
    CLOSED: "סגור"
  },
  title: "לקוחות פוטנציאליים",
  channels: "ערוצי פרסום",
  searchPlaceholder: "חפשו לפי שם, טלפון, מייל, מספר ליד",
  download: "הורדת Excel",
  newLead: "הוספת לקוח פוטנציאלי"
}

import type { IncomeType } from "@/models/enums";
import type { DateTimeString } from "@/models/interfaces";
import type { DateRangeInput } from "@/models/api/common/BaseModels";

//#region inputs
export type VatReportInput = DateRangeInput;
export type AdvanceTaxReportInput = DateRangeInput;
export type ProfitAndLossReportInput = DateRangeInput & {
  emails: string[] | null;
};
export type OverviewReportInput = DateRangeInput & {
  includeVat: boolean;
}
export type CollectionReportInput = {
  mode: string;
}
export type AccountingReportInput = {
  mode: "my-payments" | "payments-to-doctors";
  doctorId?: number;
  doctorPaymentId?: number;
  doctorIds: number[] | null;
}
export type TreatmentEntryInput = {
  entityId: number;
  isTreatmentPlan: boolean;
  calculatedAmount: number;
}
export type AddPaymentInput = {
  doctorId: number;
  details: string;
  entries: TreatmentEntryInput[];
}
export type FilterAccountingReportInput = {
  doctorIds: number[];
}
//#endregion

//#region models
export interface VatReportModel {
  amountLiableForVat: number;
  amountExemptionVat: number;
  amountVat: number;

  expensesAmount: number;
  expensesAmountVat: number;

  totalVatPaid: number;
}

export interface AdvanceTaxReportModel {
  businessTurnover: number;
  advanceTaxPercent: number;
  advanceTaxResult: number;
  totalWithholdingTax: number;
  totalAdvancePaid: number;
}

export interface PatientReportModel {
  id: number;
  total: number;
  fullName: string;
}

export interface PatientsCollectionModel {
  patientsCount: number;
  totalAmount: number;
}

export interface DoughnutModel {
  name: string;
  percent: number;
}

export interface MonthlyFinancialModel {
  year: number;
  month: number;
  amount: number;
}

export interface MonthlyOverviewModel {
  groupYear: number;
  groupMonth: number;
  income: MonthlyFinancialModel;
  expense: MonthlyFinancialModel;
}

export interface OverviewReportModel {
  amountIncomes: number;
  amountExpenses: number;

  months: MonthlyOverviewModel[];

  doughnutIncomes: DoughnutModel[];
  doughnutExpenses: DoughnutModel[];
}

export interface PaymentToDoctorModel {
  doctorId: number;
  doctorName: string;
  doctorImage: string;
  amountOwed: number;
}

export interface TreatmentModel {
  readonly id: string;

  entityId: number;
  isTreatmentPlan: boolean;
  treatmentDate: DateTimeString;
  treatmentDescription: string;

  doctorId: number;
  doctorName: string;
  doctorImage: string;
  doctorPercentage: number;

  patientId: number;
  patientName: string;
  patientPhoto: string;

  incomes: {
    id: number | null;
    incomeType: IncomeType | null;
    financialNumber: number | null;
  }[]

  amount: number;
  readonly calculatedAmountForDoctor: number;
}

export interface CollectionReportModel {
  amountTotal: number;
  doughnut: DoughnutModel[];
  patients: PatientReportModel[];
}
//#endregion

//#region models fields
export const treatmentModelAllFields = `
  id

  entityId
  isTreatmentPlan
  treatmentDate
  treatmentDescription

  doctorId
  doctorName
  doctorImage
  doctorPercentage

  patientId
  patientName
  patientPhoto

  incomes {
    id
    incomeType
    financialNumber
  }

  amount
  calculatedAmountForDoctor
`;

export const overviewReportModelAllFields = `
  amountIncomes
  amountExpenses
  months {
    groupYear
    groupMonth
    income { year month amount }
    expense { year month amount }
  }
  doughnutIncomes { name percent }
  doughnutExpenses { name percent }
`;

export const collectionReportModelAllFields = `
  amountTotal
  doughnut {
    name
    percent
  }
  patients {
    id
    total
    fullName
  }
`;
//#endregion

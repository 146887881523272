export default {
  businessType: {
    NONE: "None",
    COMPANY: 'חברה בע"מ',
    INDIVIDUAL_BUSINESS: "עוסק מורשה",
    SMALL_BUSINESS: "עוסק פטור"
  },
  serviceType: {
    NONE: "None",
    SERVICE: "טיפול",
    ITEM: "פריט"
  },
  socialNetwork: {
    FACEBOOK: "Facebook",
    INSTAGRAM: "Instagram",
    LINKDIN: "Linkdin",
    YOUTUBE: "Youtube",
    TWITTER: "Twitter",
    TIK_TOK: "TikTok"
  },
  appointmentStatus: {
    NONE: "None",
    NEW: "חדש",
    CANCELED: "ביטול",
    REMINDER_SENT: "תזכורת נשלחה",
    APPROVED: "אושר",
    TOOK_PLACE: "הגיע",
    DID_NOT_COME: "לא הגיע",
    deleted: "נמחק",
  },
  taskStatus: {
    NONE: "None",
    NEW: "חדש",
    CLOSED: "בוצע",
  },
  incomeType: {
    NONE: "None",
    ESTIMATE: "הצעת מחיר",
    PROFORMA_INVOICE: "חשבון עסקה",
    INVOICE: "חשבונית מס",
    INVOICE_RECEIPT: "חשבונית מס קבלה",
    RECEIPT: "קבלה",
    INVOICE_REFOUND: "חשבונית זיכוי",
    RECEIPT_REFOUND: "קבלה זיכוי"
  },
  incomeStatus: {
    NONE: "None",
    OPEN: "פתוח",
    CLOSED: "סגור",
    INVOICED: "נוצרה חשבונית",
    MANUALLY_CLOSED: "מסמך סומן ידנית כסגור",
    DRAFT: "טיוטה",
    WAIT_PAYMENT: "ממתין לתשלום"
  },
  medicalFormStatus: {
    NONE: "None",
    NEW: "חדש",
    SENT: "נשלח",
    VIEWED: "נצפה",
    FILLED_UP: "חתום",
  },
  receiptPaymentType: {
    NONE: "None",
    CHEQUE: "צ'ק",
    BANK_TRANSFER: "העברה בנקאית",
    CREDIT_CARD: "כרטיס אשראי",
    CASH: "מזומן",
    WITH_HOLDING_TAX: "ניכוי מס במקור"
  },
  creditDealType: {
    NONE: "None",
    REGULAR: "רגיל",
    PAYMENTS: "תשלומים",
    CREDIT: "קרדיט",
    CHARGE_DECLINED: "חיוב נדחה",
    OTHER: "אחר"
  },
  creditBrand: {
    NONE: "None",
    ISRACARD: "ישראכרט",
    VISA: "ויזה",
    MASTER_CARD: "מאסטרקארד",
    AMERICAN_EXPRESS: "אמריקן אקספרס",
    DINERS: "דיינרס"
  },
  treatmentStageStatus: {
    NONE: "None",
    ESTIMATE: "הצעת מחיר",
    ACTIVE: "פעיל",
    FINISHED: "גמור",
    CANCELED: "מבוטל",
  },
  frequency: {
    NONE: "None",
    ONE_TIME: "חד פעמית",
    WEEKLY: "כל שבוע",
    BI_WEEKLY: "כל שבועיים",
    MONTHLY: "כל חודש",
    BI_MONTHLY: "כל חודשיים"
  },
  dayOfWeek: {
    SUNDAY: "יום א'",
    MONDAY: "יום ב'",
    TUESDAY: "יום ג'",
    WEDNESDAY: "יום ד'",
    THURSDAY: "יום ה'",
    FRIDAY: "יום ו'",
    SATURDAY: "יום ש'"
  },
  evenOrOdd: {
    NONE: "None",
    EVEN: "זוגי",
    ODD: "אי זוגי"
  },
  historyAction: {
    NONE: "None",
    CREATE: "הוסף",
    UPDATE: "עדכן",
    DELETE: "למחוק",
  },
  vatType: {
    NONE: "None",
    NORMAL: "רגיל",
    NO_VAT: "ללא מע״מ (אילת וחו״ל)"
  },
  vatBeforeOrInclude: {
    NONE: "None",
    BEFORE_VAT: "לפני",
    INCLUDE_VAT: "כולל"
  },
  messageProvider: {
    NONE: "None",
    WHATSAPP: "ווטסאפ",
    SMS: "SMS",
    EMAIL: "דוא\"ל"
  },
  messageTemplateType: {
    NONE: "None",
    GENERAL: "General",
    PATIENT_APPOINTMENT_CREATED: "PatientAppointmentCreated",
    PATIENT_APPOINTMENT_REMINDER: "PatientAppointmentReminder",
    PATIENT_APPOINTMENT_CANCELED: "PatientAppointmentCanceled",
    ACCOUNTANT_INCOMES_REPORT: "AccountantIncomesReport",
    ACCOUNTANT_EXPENSES_REPORT: "AccountantExpensesReport",
    LABORATORY_LABORATORY_WORK_CREATED: "LaboratoryLaboratoryWorkCreated",
    USER_NEW_TASK_CREATED: "UserNewTaskCreated",
    PATIENT_MEDICAL_FORM_POPUP: "PatientMedicalFormPopup",
    PATIENT_APPOINTMENT_LINK_POPUP: "PatientAppointmentLinkPopup",

    PATIENT_FINANCIAL_DOCUMENT_CREATED: "PatientFinancialDocumentCreated",
    PATIENT_FINANCIAL_DOCUMENT_POPUP: "PatientFinancialDocumentPopup",
    PATIENT_FINANCIAL_DOCUMENT_WITH_PAYMENT_LINK_POPUP: "PatientFinancialDocumentWithPaymentLinkPopup",
    PATIENT_FINANCIAL_DOCUMENT_WITH_PAYMENT_LINK_CREATED: "PatientFinancialDocumentWithPaymentLinkCreated",

    PATIENT_APPOINTMENT_FOLLOW_UP: "PatientAppointmentFollowUp",
    PATIENT_MEDICAL_FORM_REMINDER: "PatientMedicalFormReminder",
    PATIENT_TREATMENT_PLAN_POPUP: "PatientTreatmentPlanPopup",
    PATIENT_SELF_REGISTRATION_POPUP: "PatientSelfRegistrationPopup",
    PATIENT_PRESCRIPTION_CREATED: "PatientPrescriptionCreated",
    PATIENT_APPOINTMENT_CHANGED: "PatientAppointmentChanged",
    PATIENT_RECALL_POPUP: "PatientRecallPopup",
    PATIENT_DOCUMENT_POPUP: "PatientDocumentPopup",
  },
  laboratoryWorkStatus: {
    NONE: "None",
    NEW: "חדש",
    SENT_TO_LABORATORY: "עבודה נשלחה למעבדה",
    BACK_FROM_LABORATORY: "עבודה התקבלה",
    CLOSED: "נסגרה ב"
  },
  treatmentPaidStatus: {
    NONE: "None",
    NO: "לא",
    YES: "כן",
    PARTIAL: "חלקי",
    IN_PLAN: "In plan",
    MARKED_AS_PAID: "שולם",
  },
  patientValidationReason: {
    NONE: "None",
    PHONE: "טלפון",
    EMAIL: "דוא\"ל",
    PASSPORT_NUMBER: "מספר ת\"ז",
    FILE_NUMBER: "מספר תיק"
  }
}

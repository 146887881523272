export default {
  forPatient: "לכבוד:",
  forPassport: "ח.פ./ת.ז.",
  copy: "העתק נאמן למקור",
  forCompanyNumber: "(ח.פ):",
  quantity: "כמות",
  detail: "פירוט",
  price: "מחיר",
  total: 'סה"כ',
  rounding: "עיגול",
  discount: "הנחה",
  vat: 'מע"מ',
  totalPayment: 'סה"כ לתשלום',
  paymentDetails: "פרטי תשלומים",
  paymentMethod: "אמצעי תשלום",
  date: "תאריך",
  sum: "סכום",
  signature: "חתימה",
  bySoftware: "הופק על ידי תוכנת מדפורם"
}
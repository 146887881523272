// https://csharptotypescript.azurewebsites.net/
// https://convertcase.net/

export enum AccountingReportingMethod {
  None = "NONE",
  CashBased = "CASH_BASED",
  InvoiceBased = "INVOICE_BASED"
}

export enum AppointmentStatus {
  None = "NONE",
  New = "NEW",
  Canceled = "CANCELED",
  ReminderSent = "REMINDER_SENT",
  Approved = "APPROVED",
  TookPlace = "TOOK_PLACE",
  DidNotCome = "DID_NOT_COME"
}

export enum Area {
  None = "NONE",
  B = "B",
  D = "D",
  L = "L",
  M = "M",
  I = "I"
}

export enum AttendanceType {
  None = "NONE",
  Automatic = "AUTOMATIC",
  Manual = "MANUAL"
}

export enum AccountingVatType {
  None = "NONE",
  NoVat = "NO_VAT",
  Monthly = "MONTHLY",
  BiMonthly = "BI_MONTHLY"
}

export enum BusinessType {
  None = "NONE",
  SmallBusiness = "SMALL_BUSINESS",
  IndividualBusiness = "INDIVIDUAL_BUSINESS",
  Company = "COMPANY"
}

export enum CalendarType {
  Day = "DAY",
  Week = "WEEK",
  List = "LIST"
}

export enum Classification {
  None = "NONE",
  _1306 = "_1306",
  _1307 = "_1307",
  _1310 = "_1310",
  _1320 = "_1320",
  _1330 = "_1330",
  _1340 = "_1340",
  _1390 = "_1390",
  _3011 = "_3011",
  _3012 = "_3012",
  _3015 = "_3015",
  _3055 = "_3055",
  _3060 = "_3060",
  _3066 = "_3066",
  _3067 = "_3067",
  _3068 = "_3068",
  _3070 = "_3070",
  _3075 = "_3075",
  _3080 = '_3080',
  _3085 = "_3085",
  _3090 = "_3090",
  _3100 = "_3100",
  _3120 = "_3120",
  _3511 = "_3511",
  _3512 = "_3512",
  _3515 = "_3515",
  _3520 = "_3520",
  _3535 = "_3535",
  _3540 = "_3540",
  _3545 = "_3545",
  _3550 = "_3550",
  _3555 = "_3555",
  _3560 = "_3560",
  _3566 = "_3566",
  _3567 = "_3567",
  _3568 = "_3568",
  _3570 = "_3570",
  _3575 = "_3575",
  _3580 = "_3580",
  _3590 = "_3590",
  _3595 = "_3595",
  _3600 = "_3600",
  _3620 = "_3620",
  _3625 = "_3625",
  _3640 = "_3640",
  _3650 = "_3650",
  _3660 = "_3660",
  _3665 = "_3665",
  _3680 = "_3680",
  _3685 = '_3685',
  _5010 = "_5010",
  _5090 = "_5090",
  _8040 = "_8040",
  _8050 = "_8050",
  _8060 = "_8060",
  _8080 = "_8080",
  _8090 = "_8090"
}

export enum CreditBrand {
  None = "NONE",
  Isracard = "ISRACARD",
  Visa = "VISA",
  MasterCard = "MASTER_CARD",
  AmericanExpress = "AMERICAN_EXPRESS",
  Diners = "DINERS"
}

export enum CreditDealType {
  None = "NONE",
  Regular = "REGULAR",
  Payments = "PAYMENTS",
  Credit = "CREDIT",
  ChargeDeclined = "CHARGE_DECLINED",
  Other = "OTHER"
}

export enum DayOfWeek {
  Sunday = "SUNDAY",
  Monday = "MONDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
  Thursday = "THURSDAY",
  Friday = "FRIDAY",
  Saturday = "SATURDAY"
}

export enum DefaultOpeningScreen {
  None = "NONE",
  Dashboard = "DASHBOARD",
  Patients = "PATIENTS",
  Calendar = "CALENDAR"
}

export enum DiscountType {
  None = "NONE",
  Percent = "PERCENT",
  Amount = "AMOUNT",
  FixPrice = "FIX_PRICE"
}

export enum DocumentType {
  None = "NONE",
  PaymentConfirmation = "PAYMENT_CONFIRMATION",
  Invoice = "INVOICE",
  InvoiceReceipt = "INVOICE_RECEIPT",
  CreditInvoice = "CREDIT_INVOICE",
  Receipt = "RECEIPT",
  DonationReceipt = "DONATION_RECEIPT"
}

export enum EmailProviderType {
  None = "NONE",
  Medform = "MEDFORM",
  Gmail = "GMAIL",
  Outlook = "OUTLOOK"
}

export enum EmailSubject {
  None = "NONE",
  ProformaInvoiceNumber = "PROFORMA_INVOICE_NUMBER",
  ProformaInvoice = "PROFORMA_INVOICE",
  DocumentDescription = "DOCUMENT_DESCRIPTION"
}

export enum EntityType {
  None = "NONE",
  PatientNote = "PATIENT_NOTE",
  Treatment = "TREATMENT",
  TreatmentPlan = "TREATMENT_PLAN"
}

export enum EvenOrOdd {
  None = "NONE",
  Even = "EVEN",
  Odd = "ODD"
}

export enum ExpensePaymentType {
  None = "NONE",
  Check = "CHECK",
  BankTransfer = "BANK_TRANSFER",
  CreditCard = "CREDIT_CARD",
  Paypal = "PAYPAL",
  Cash = "CASH",
  PaymentApp = "PAYMENT_APP",
  Other = "OTHER"
}

export enum FamilyStatus {
  None = "NONE",
  Bachelor = "BACHELOR",
  Married = "MARRIED"
}

export enum ForecastType {
  None = "NONE",
  Income = "INCOME",
  Expense = "EXPENSE"
}

export enum Frequency {
  None = "NONE",
  OneTime = "ONE_TIME",
  Weekly = "WEEKLY",
  BiWeekly = "BI_WEEKLY",
  Monthly = "MONTHLY",
  BiMonthly = "BI_MONTHLY"
}

export enum Gender {
  None = "NONE",
  Male = "MALE",
  Female = "FEMALE"
}

export enum HistoryAction {
  None = "NONE",
  Create = "CREATE",
  Update = "UPDATE",
  Delete = "DELETE"
}

export enum IncomeStatus {
  None = "NONE",
  Open = "OPEN",
  Closed = "CLOSED",
  Invoiced = "INVOICED",
  ManuallyClosed = "MANUALLY_CLOSED",
  Draft = "DRAFT",
  WaitPayment = "WAIT_PAYMENT"
}

export enum IncomeType {
  None = "NONE",
  Estimate = "ESTIMATE",
  ProformaInvoice = "PROFORMA_INVOICE",
  Invoice = "INVOICE",
  InvoiceReceipt = "INVOICE_RECEIPT",
  Receipt = "RECEIPT",
  InvoiceRefound = "INVOICE_REFOUND",
  ReceiptRefound = "RECEIPT_REFOUND"
}

export enum LaboratoryWorkStatus {
  None = "NONE",
  New = "NEW",
  SentToLaboratory = "SENT_TO_LABORATORY",
  BackFromLaboratory = "BACK_FROM_LABORATORY",
  Closed = "CLOSED"
}

export enum Language {
  None = "NONE",
  English = "ENGLISH",
  Hebrew = "HEBREW"
}

export enum MedicalFormStatus {
  None = "NONE",
  New = "NEW",
  Sent = "SENT",
  Viewed = "VIEWED",
  FilledUp = "FILLED_UP"
}

export enum MessageProvider {
  None = "NONE",
  Whatsapp = "WHATSAPP",
  SMS = "SMS",
  Email = "EMAIL"
}

export enum MessageTemplateType {
  None = "NONE",
  General = "GENERAL",
  PatientAppointmentCreated = "PATIENT_APPOINTMENT_CREATED",
  PatientAppointmentReminder = "PATIENT_APPOINTMENT_REMINDER",
  PatientAppointmentCanceled = "PATIENT_APPOINTMENT_CANCELED",
  AccountantIncomesReport = "ACCOUNTANT_INCOMES_REPORT",
  AccountantExpensesReport = "ACCOUNTANT_EXPENSES_REPORT",
  LaboratoryLaboratoryWorkCreated = "LABORATORY_LABORATORY_WORK_CREATED",
  UserNewTaskCreated = "USER_NEW_TASK_CREATED",
  PatientMedicalFormPopup = "PATIENT_MEDICAL_FORM_POPUP",
  PatientAppointmentLinkPopup = "PATIENT_APPOINTMENT_LINK_POPUP",

  PatientFinancialDocumentCreated = "PATIENT_FINANCIAL_DOCUMENT_CREATED",
  PatientFinancialDocumentPopup = "PATIENT_FINANCIAL_DOCUMENT_POPUP",
  PatientFinancialDocumentWithPaymentLinkPopup = "PATIENT_FINANCIAL_DOCUMENT_WITH_PAYMENT_LINK_POPUP",
  PatientFinancialDocumentWithPaymentLinkCreated = "PATIENT_FINANCIAL_DOCUMENT_WITH_PAYMENT_LINK_CREATED",

  PatientAppointmentFollowUp = "PATIENT_APPOINTMENT_FOLLOW_UP",
  PatientMedicalFormReminder = "PATIENT_MEDICAL_FORM_REMINDER",
  PatientTreatmentPlanPopup = "PATIENT_TREATMENT_PLAN_POPUP",
  PatientSelfRegistrationPopup = "PATIENT_SELF_REGISTRATION_POPUP",
  PatientPrescriptionCreated = "PATIENT_PRESCRIPTION_CREATED",
  PatientAppointmentChanged = "PATIENT_APPOINTMENT_CHANGED",
  PatientRecallPopup = "PATIENT_RECALL_POPUP",
  PatientDocumentPopup = "PATIENT_DOCUMENT_POPUP"
}

export enum NotificationType {
  None = "NONE",
  MobileRegistration = "MOBILE_REGISTRATION",
  NewLead = "NEW_LEAD",
  NewWebAppointmentsExistingPatient = "NEW_WEB_APPOINTMENTS_EXISTING_PATIENT",
  NewWebAppointmentsNew = "NEW_WEB_APPOINTMENTS_NEW",
  PatientCanceledAppointment = "PATIENT_CANCELED_APPOINTMENT",
  PatientChangedAppointment = "PATIENT_CHANGED_APPOINTMENT",
  PatientApprovedAppointment = "PATIENT_APPROVED_APPOINTMENT",
  PatientFilledOutMedicalForm = "PATIENT_FILLED_OUT_MEDICAL_FORM",
  PatientNotApprovedAppointment = "PATIENT_NOT_APPROVED_APPOINTMENT"
}

export enum PatientFamilyType {
  None = "NONE",
  Father = "FATHER",
  Mother = "MOTHER",
  BrotherOrSister = "BROTHER_OR_SISTER",
  Partner = "PARTNER",
  Child = "CHILD",
  Grandfather = "GRANDFATHER",
  Grandmother = "GRANDMOTHER",
  Other = "OTHER"
}

export enum PatientValidationReason {
  None = "NONE",
  Phone = "PHONE",
  Email = "EMAIL",
  PassportNumber = "PASSPORT_NUMBER",
  FileNumber = "FILE_NUMBER"
}

export enum PaymentTerms {
  None = "NONE",
  Immediate = "IMMEDIATE",
  CurrentMonth = "CURRENT_MONTH",
  CurrentMonth10 = "CURRENT_MONTH10",
  CurrentMonth30 = "CURRENT_MONTH30",
  CurrentMonth60 = "CURRENT_MONTH60",
  CurrentMonth90 = "CURRENT_MONTH90"
}

export enum PriceOperationType {
  None = "NONE",
  Increase = "INCREASE",
  Decrease = "DECREASE"
}

export enum ReceiptPaymentType {
  None = "NONE",
  Cheque = "CHEQUE",
  BankTransfer = "BANK_TRANSFER",
  CreditCard = "CREDIT_CARD",
  Cash = "CASH",
  WithHoldingTax = "WITH_HOLDING_TAX"
}

export enum ReminderLinkType {
  None = "NONE",
  Approval = "APPROVAL",
  ChangeApparent = "CHANGE_APPARENT"
}

export enum SettingType {
  None = "NONE",
  String = "STRING",
  Int = "INT",
  Data = "DATA",
  DateTime = "DATE_TIME",
  Decimal = "DECIMAL",
  Enum = "ENUM",
  Bool = "BOOL",
  Json = "JSON",
  Editor = "EDITOR"
}

export enum SendingFrequency {
  None = "NONE",
  Monthly = "MONTHLY",
  BiMonthly = "BI_MONTHLY"
}

export enum ServiceType {
  None = "NONE",
  Service = "SERVICE",
  Item = "ITEM"
}

export enum ShowCalendarsType {
  None = "NONE",
  All = "ALL",
  My = "MY",
  Select = "SELECT"
}

export enum ShowPatientType {
  None = "NONE",
  AllowAll = "ALLOW_ALL",
  AllowSeeAllEditMy = "ALLOW_SEE_ALL_EDIT_MY",
  AllowMy = "ALLOW_MY",
  AllowSeeMyOnly = "ALLOW_SEE_MY_ONLY"
}

export enum ShowType {
  None = "NONE",
  All = "ALL",
  My = "MY"
}

export enum SocialNetwork {
  None = "NONE",
  Facebook = "FACEBOOK",
  Instagram = "INSTAGRAM",
  Linkdin = "LINKDIN",
  Youtube = "YOUTUBE",
  Twitter = "TWITTER",
  TikTok = "TIK_TOK"
}

export enum StorageFileType {
  None = "NONE",
  NoneId = 0,
  PatientPhoto = "PATIENT_PHOTO",
  PatientPhotoId = 1,
  DoctorPhoto = "DOCTOR_PHOTO",
  DoctorPhotoId = 2,
  TaskAttachment = "TASK_ATTACHMENT",
  TaskAttachmentId = 3,
  LaboratoryWorkAttachment = "LABORATORY_WORK_ATTACHMENT",
  LaboratoryWorkAttachmentId = 4,
  MessageTemplateAttachment = "MESSAGE_TEMPLATE_ATTACHMENT",
  MessageTemplateAttachmentId = 5,
  PatientFiles = "PATIENT_FILES",
  PatientFilesId = 6,
  PatientFilesFromChat = "PATIENT_FILES_FROM_CHAT",
  PatientFilesFromChatId = 7,
  ExpenseFiles = "EXPENSE_FILES",
  ExpenseFilesId = 8,
  LeadAttachment = "LEAD_ATTACHMENT",
  LeadAttachmentId = 9,
  IncomePdf = "INCOME_PDF",
  IncomePdfId = 10,
  RootSettingImages = "ROOT_SETTING_IMAGES",
  RootSettingImagesId = 11,
  EmailAttachment = "EMAIL_ATTACHMENT",
  EmailAttachmentId = 12,
  MinisiteImages = "MINISITE_IMAGES",
  MinisiteImagesId = 13,
  MinisiteGalleryPhoto = "MINISITE_GALLERY_PHOTO",
  MinisiteGalleryPhotoId = 14
}

export enum TaskPriority {
  None = "NONE",
  Low = "LOW",
  Normal = "NORMAL",
  Hight = "HIGHT",
  Urgent = "URGENT",
  Immediate = "IMMEDIATE"
}

export enum TaskStatus {
  None = "NONE",
  New = "NEW",
  Closed = "CLOSED"
}

export enum TreatmentDenture {
  None = "NONE",
  Fixed = "FIXED",
  Removable = "REMOVABLE",
  Mixed = "MIXED"
}

export enum TreatmentPaidStatus {
  None = "NONE",
  No = "NO",
  Yes = "YES",
  Partial = "PARTIAL",
  InPlan = "IN_PLAN",
  MarkedAsPaid = "MARKED_AS_PAID"
}

export enum TreatmentStageStatus {
  None = "NONE",
  Estimate = "ESTIMATE",
  Active = "ACTIVE",
  Finished = "FINISHED",
  Canceled = "CANCELED"
}

export enum VatBeforeOrInclude {
  None = "NONE",
  BeforeVat = "BEFORE_VAT",
  IncludeVat = "INCLUDE_VAT"
}

export enum VatType {
  None = "NONE",
  Normal = "NORMAL",
  NoVat = "NO_VAT"
}

export default {
  createUser: "יצירת משתמש חדש",
  updateUser: "עדכן משתמש: {0}",
  tabChanged: "נתונים לא ישמרו. האם אתם בטוחים שאתם רוצים לעבור לטב אחר?",
  tabs: {
    general: "כללי",
    password: "סיסמה",
    permissions: "הרשאות",
    doctorInfo: "נתוני רופא",
    calendar: "יומן",
    closedDates: "ימי חופשה",
    externalCalendars: "יומנים חיצוניים",
    services: "ניהול טיפולים אונליין",
  },
  roles: {
    doctoradmin: "רופא מנהל",
    doctor: "רופא",
    secretary: "מזכירה רפואית"
  },
  general: {
    firstName: "שם פרטי",
    lastName: "שם משפחה",
    userName: "שם משתמש",
    email: "מייל",
    active: "משתמש פעיל",
    saveFilters: "שמירת נתוני סינון",
    password: "סיסמה",
    passwordRepeat: "אימות סיסמה",
    roles: "תפקיד",
    rfidTag: "תג זיהוי שעון נוכחות",
    doctorPassport: "תעודת זהות",
    doctorPassportValidation: "ת.ז. צריך להיות 9 ספרות",
    timezone: "אזור זמן"
  },
  password: {
    message: "כדי לשמור את הסיסמה הקיימת, יש להשאיר שדה זה ריק",
    validation: {
      instructions: "הסיסמה שלך חייבת לעמוד בקריטריונים הבאים: לפחות 8 תווים, כוללים אותיות גדולות וקטנות, לפחות מספר אחד, ותווים מיוחדים.",
      userNotFound: "משתמש לא קיים",
      wasUsed: "הסיסמה הייתה בשימוש בעבר"
    }
  },
  permissions: {
    title: "הרשאות",
    roleName: "תפקיד",
    showPatient: "הצגת מטופלים",
    showPatientOptions: [
      "הצגת כל המטופלים",
      "הצגת כל המטופלים ועריכת רק המשויכים לרופא",
      "הצגת ועריכת המטופלים המשויכים לרופא בלבד",
      "הצגת המטופלים המשויכים לרופא בלבד ללא אפשרות לערוך",
    ],
    showCalendars: "להציג יומן",
    showCalendarsOptions: [
      "הכל",
      "שלי בלבד",
      "בחירה",
    ],
    showOptions: [
      "לא להציג",
      "הכל",
      "שלי בלבד",
    ],

    financial: "הנהלת חשבונות",
    financialReports: "הנהלת חשבונות - דוחות",
    incomes: "הנהלת חשבונות - הכנסות",
    expenses: "הנהלת חשבונות - הוצאות",
    forecast: "הנהלת חשבונות - תזרים",
    deposit: "הנהלת חשבונות - הפקדות",
    accountingReport: "הנהלת חשבונות - דוח התחשבנות",

    appointments: "יומן",
    leads: "לידים",
    laboratoryWorks: "עבודות מעבדה",
    tasks: "משימות",
    attendance: "דיווח שעות",
    settings: "הגדרות",

    emails: "מיילים",
    recall: "ריקול",
    chat: "הודעות",
    users: "משתמשים",
    patients: "מטופלים",
  },
  doctor: {
    display: "תצוגה",
    displayName: "שם להצגה",
    licenseNumber: "מספר רשיון",
    jobDescription: "תיאור תפקיד",
    profession: "מקצוע",
    defaultOpeningScreen: "עמוד ראשון לאחר כניסה",
    defaultScreenOptions: ["דשבורד", "מטופלים", "תור חדש"],
    doctorImage: "תמונות רופא",
    signature: "חתימה (לחצו כדי לעלות)",

    financial: "פיננסי",
    percentFromTreatments: "קיים מודל תשלום באחוזים",
    percentFromTreatmentsValue: "שיעור תשלום באחוזים מכל טיפול",

    calendar: "יומן",
    haveCalendar: "למשתמש קיים יומן",
    maxDaysToSetupTheEvent: "טווח ימים זמין לתיאום תור",
    minHoursToSetupEvent: "טווח שעות יומי זמין לתיאום תור",

    calendarColors: "צבעים ביומן",
    appointmentItemColor: "צבע רקע פגישה",
    appointmentItemFontColor: "צבע טקסט פגישה",
    inactiveWorkHoursColor: "צבע רקע שעות לא פעילות",
    inactiveWorkHoursFontColor: "צבע גופן שעות עבודה לא פעילות",
    tmpCustomerAppointmentColor: "צבע פגישה עם הלקוח",
    tmpCustomerAppointmentFontColor: "צבע גופן לפגישת לקוח",

    calendarHaveColors: "לאפשר צבעים",
    calendarGridMinutes: "חלוקת משבצות זמן ביומן בדקות",
    calendarDayStart: "שעת תחילת פעילות ביומן",
    calendarDayEnd: "שעת סיום פעילות ביומן",
    calendarAddAppointmentMinutes: "משך התור בדקות",

    error: "שגיאה בשמירת משתמש.",
    saved: "משתמש נשמר בהצלחה!",
    upload: "לחצו כדי לעלות תמונה"
  },
  calendar: {
    title: "שעות זמינות לקביעת תור",
    share: "שתף לינק",
    error: "שמירה לא הצליחה",
    saved: "נשמר!",
    reload: "ריענון נתונים"
  },
  dates: {
    title: "ימי חופשה ביומן",
    table: {
      columns: {
        fromDate: "מתאריך",
        toDate: "עד תאריך",
      },
    },
    create: "הוספה",
    reallyRemove: "האם אתה באמת רוצה להסיר תאריכים?",
    error: "שמירת התאריכים נכשלה.",
    saved: "נשמר!"
  },
  external: {
    title: "חיבור ליומן גוגל או Outlook",
    gmail: "Google",
    office365: "Office 365",
    connect: "חיבור"
  },
  services: {
    title: "שירותים",
    table: {
      columns: {
        id: "ID",
        code: "קוד טיפול",
        name: "שם תבנית טיפול",
        categoryName: "קטגוריית תבנית טיפול",
        materialPrice: "מחיר",
        active: "פעיל",
      }
    }
  }
}
